<template>
  <v-dialog
    v-model="isOpen"
    @click:outside="$emit(`closeDialog`)"
    max-width="600px"
    max-height="320px"
  >
    <v-card class="mb-4">
      <v-card-title class="cursor-select-none">
        <span
          class="d-inline-block text-h5 text-truncate"
          style="max-width: 420px"
          :title="fileName"
          >{{ fileName }}
        </span>

        <span class="text-subtitle-1 ml-auto"
          >(total version: {{ itemList.length }})</span
        >
      </v-card-title>

      <div class="list-wrapper">
        <v-list>
          <v-list-item v-for="(item, i) in itemList" :key="i">
            <v-list-item-content
              class="d-inline-block text-truncate"
              :title="'Id:' + item.file_id + ' ' + item.version"
            >
              [Id: {{ item.file_id }}] {{ item.version }}
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                color="blue darken-1"
                text
                @click="openDataDic(item.file_id)"
                >view file</v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </div>
      <v-card-actions>
        <v-spacer />
        <v-btn color="blue darken-1" text @click="$emit(`closeDialog`)">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    isOpen: Boolean,
    fileName: String,
    itemList: Array,
  },
  methods: {
    async openDataDic(id) {
      const toDataDict = this.$router.resolve({
        name: "datadictionary",
        params: { id },
      });
      window.open(toDataDict.href, "_blank");
    },
  },
};
</script>
<style scoped lang="scss">
.list-wrapper {
  width: 100%;
  max-height: 240px;
  overflow-y: auto;
  border: 1px solid #dfdfdf;
}
</style>
