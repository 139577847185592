<template>
  <v-dialog
    v-model="dialog"
    @click:outside="$emit(`saveDialog`, { dialog: false })"
    max-width="600px"
  >
    <v-card class="mb-4">
      <v-card-title>
        <span class="text-h5">Get S3Key</span>
      </v-card-title>
      <v-card-text>
        <v-container v-if="isListDataNode">
          <p>Select file version</p>
          <v-select
            v-model="selectNode"
            outlined
            placeholder="Please select file version"
            item-text="version"
            item-value="file_id"
            :items="dataListDataNode"
          />
          <v-text-field
            :disabled="selectNode == null"
            readonly
            v-model="s3KeyLink"
            outlined
            dense
          >
            <template slot="append">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    :disabled="selectNode == null"
                    icon
                    v-on="on"
                    @click="copyS3Key(s3KeyLink)"
                  >
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>Copy to clipboard</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-container>
        <v-container v-else>
          <v-text-field readonly v-model="S3key" outlined dense>
            <template slot="append">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="copyS3Key(S3key)">
                    <v-icon>mdi-content-copy</v-icon>
                  </v-btn>
                </template>
                <span>Copy to clipboard</span>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    dataListDataNode: Array,
    isListDataNode: {
      type: Boolean,
      default: false,
    },
    S3key: {
      type: String,
      default: "",
    },
  },
  data: () => ({
    selectNode: null,
    s3KeyLink: "",
  }),
  methods: {
    copyS3Key: (val) => navigator.clipboard.writeText(val),
    async getS3Key(id) {
      const { data } = await this.$store.dispatch(
        "getFileRetrieve",
        parseInt(id)
      );
      return data.s3_key;
    },
  },
  watch: {
    async selectNode() {
      if (this.selectNode) {
        // Get S3 key when select data node
        let key = await this.getS3Key(this.selectNode);
        this.s3KeyLink = `s3://${process.env.VUE_APP_BUCKETNAME}/${key}/*.parquet`;
      }
    },
    dialog() {
      if (!this.dialog) {
        this.selectNode = null;
        this.s3KeyLink = "";
      }
    },
  },
};
</script>

<style></style>
