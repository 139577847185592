export default {
  methods: {
    resolveUTCTime(time) {
      if (time === null || time === "" || time === undefined) return "-";

      const Time = new Date(time);
      const timeString =
        `${Time.getFullYear()}/${(Time.getMonth() + 1)
          .toLocaleString()
          .padStart(2, "0")}/${Time.getDate()
          .toLocaleString()
          .padStart(2, "0")} ` +
        `${Time.getHours()}`.padStart(2, "0") +
        `:` +
        `${Time.getMinutes()}`.padStart(2, "0");
      return timeString;
    },
  },
};
